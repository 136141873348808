import { AError } from "../../classes/AError.js";
import { ALoopTimer } from "../../classes/ALoopTimer.js";
import { ALoopTimerAsync } from "../../classes/ALoopTimerAsync.js";
import { AEngine, sleep } from "../../core/AEngine.js";
import { MAP_OPTIONS } from "../../core/maps/AMapStructs.js";
import { AKpiBlockSessions } from "../../kpi/AKpiBlockSessions.js";
import { AKpiSystem } from "../../kpi/AKpiSystem.js";
import { EVENTS } from "../../services/AEventService.js";
import { ATimeService } from "../../services/ATimeService.js";
import { createMap, ShowMapScans } from "../../utils/maps.js";
import { lerp } from "../../utils/tools.js";
// Go back 15 minutes in time
const START_OFFSET = 1000 * 60 * 15;
const timeService = AEngine.get(ATimeService);
export class APage {
    constructor() {
        this.SessionMarkers = {};
        this.pageLoadTime = new Date(Date.now() - START_OFFSET);
        this.lastVerificationTime = new Date(Date.now() - START_OFFSET);
        this.markersMap = {};
        const map = createMap('map');
        this.map = map;
        this.tracker = [];
        this.markers = [];
        this.fadeInMarkers = [];
        const kpiSystem = new AKpiSystem({
            showSubtitle: true,
            getView: () => '',
            onWidgetCreated: (widget) => { },
            onWidgetDeleted: (widget) => { },
            isOperationsPage: false,
            $btnCreate: $(),
            $container: $(),
            $showIfEmpty: $(),
            widgets: [],
            allowEdit: false,
            disableMenu: true,
        });
        this.kpiSessions = new AKpiBlockSessions({
            kpiSystem: kpiSystem,
            KpiType: '',
            Name: '',
            querySelector: '',
            Width: 'col-12',
            listenToSessions: false,
            autoReflow: false,
            blockList: ('.kpi-block-list'),
            blockWidth: 'col-12'
        });
        this.kpiChartTimer = new ALoopTimer(() => this.kpiSessions.refresh(), {
            loopLifeCycle: "PAGE",
            timeout: 1000
        }).start();
        Events.on(EVENTS.SESSION_CHANGE_STREAM, (SessionInfo) => {
            this.kpiSessions.updateSession(SessionInfo);
        });
        Sessions.map(info => this.kpiSessions.updateSession(info));
    }
    async init() {
        mapHelperService.prepareMapItems(MAP_OPTIONS.Default, {
            showLegend: true,
            allowExport: false,
        }).catch(AError.handle);
        this.kpiSessions.init().catch(AError.handle);
        this.handleSessionsChanged();
        const vehiclePosUpdatePerSecond = 15;
        // mapHelperService.displaySessionsOnMap({
        //   interpolate: true,
        //   lerpSpeed: vehiclePosUpdatePerSecond,
        //   sessions: this.SessionMarkers,
        //   map: this.map
        // })
        nodeSessionService.bindSessionsToMap({
            interpolate: true,
            lerpSpeed: vehiclePosUpdatePerSecond,
            mapMarkers: this.SessionMarkers,
            map: this.map,
            onChange: () => {
                return this.handleSessionsChanged();
            }
        });
        this.followVehicleTimer = new ALoopTimer(() => this.followSelectedVehicle(), {
            loopLifeCycle: "PAGE",
            timeout: 1000.0 / vehiclePosUpdatePerSecond
        }).start();
        this.refreshTimer = new ALoopTimer(() => this.tickClock(), {
            loopLifeCycle: "PAGE",
            timeout: 1000
        }).start();
        const detectionAttributes = {
            strokeOpacity: 0.733,
            strokeWeight: 3,
            fillOpacity: 0.35,
            zIndex: 10.0
        };
        this.animateTimer = new ALoopTimerAsync(async () => {
            const self = this;
            const frameReq = new Promise(requestAnimationFrame);
            const promises = [
                frameReq.then(function frameCallback(timestamp) {
                    self.fadeInMarkers = self.fadeInMarkers.filter((marker) => {
                        const { fillOpacity, strokeOpacity } = marker;
                        const lerpFinished = Math.abs(detectionAttributes.fillOpacity - fillOpacity) < 0.05;
                        if (lerpFinished) {
                            marker.setOptions({
                                fillOpacity: detectionAttributes.fillOpacity,
                                strokeOpacity: detectionAttributes.strokeOpacity
                            });
                            return false;
                        }
                        marker.setOptions({
                            fillOpacity: lerp(fillOpacity, detectionAttributes.fillOpacity, 0.03),
                            strokeOpacity: lerp(strokeOpacity, detectionAttributes.strokeOpacity, 0.03)
                        });
                        return true;
                    });
                }),
                sleep(1000.0 / 30)
            ];
            await Promise.all(promises);
        }, { loopLifeCycle: "PAGE" }).start();
    }
    async handleSessionsChanged() {
        const markers = this.SessionMarkers;
        $('#count span').text(Object.keys(markers).length);
        const $panel = $('#firstPanel table tbody');
        $panel.empty();
        const results = await Promise.all(Object.keys(markers).map((key) => this.insert($panel, markers[key])));
        const targetIsInCollection = results.reduce((a, b) => a || b);
        if (!targetIsInCollection) {
            this.target = null;
        }
    }
    async insert($panel, session) {
        const { data } = session;
        const { NodeName, StatusString, User, UserDisplayName, Gps } = data;
        const $found = $panel.find(`tr[NodeName="${NodeName}"]`);
        const gpsTime = new Date(Gps.GpsTime);
        const timeAgo = await timeService.ago(new Date(), gpsTime);
        const hoursPassed = ((Date.now() - gpsTime.getTime()) / 1000 / 60 / 60);
        if (hoursPassed > 24.0) {
            return false;
        }
        if ($found.length === 0) {
            const cls = (this.targetNodeName === NodeName) ? 'active' : '';
            const $ele = $(`
				<tr NodeName="${NodeName}" class="${cls}">
					<td>${NodeName}</td>
					<td>${UserDisplayName || User}</td>
					<td>${timeAgo}</td>
				</tr>
			`);
            $ele.on('click', (e) => this.clickRow($(e.target)));
            $panel.append($ele);
            if (this.targetNodeName === NodeName) {
                return true;
            }
        }
        else {
            console.warn(`Changing ${NodeName} status '${$found.find('td:nth-child(2)').text()}' to '${StatusString}'`);
            const $children = $found.children();
            const values = [NodeName, UserDisplayName || User];
            values.map((updatedText, i) => $children.eq(i).text(updatedText));
            $children.last().text(timeAgo);
        }
        return false;
    }
    followSelectedVehicle() {
        if (this.target) {
            const pos = this.target.getPosition();
            this.map.setCenter(pos);
        }
    }
    async tickClock() {
        if (this.SessionMarkers) {
            await Promise.all(Object.keys(this.SessionMarkers).map(async (deviceName) => {
                const textTime = this.SessionMarkers[deviceName].data.Gps.GpsTime;
                const lastDate = new Date(textTime);
                $(`[nodename="${deviceName}"] > td:last-child`).text(await timeService.ago(new Date(), lastDate));
            }));
        }
        this.refresh().catch(AError.handle);
    }
    clickRow($target) {
        const $prevTr = $target.closest('table').find('tr.active');
        const $tr = $target.is('tr') ? $target : $target.closest('tr');
        const NodeName = $target.parents('tr').attr('NodeName') || '';
        if (this.SessionMarkers.hasOwnProperty(NodeName)) {
            const teamMember = this.SessionMarkers[NodeName];
            this.map.focusOnMarker(teamMember);
            if (!$tr.is($prevTr)) {
                $prevTr.removeClass('active');
            }
            $tr.toggleClass('active');
            this.target = ($tr.hasClass('active')) ? teamMember : null;
            this.targetNodeName = ($tr.hasClass('active')) ? NodeName : null;
            // google.maps.event.trigger(teamMember, 'click')
        }
    }
    async refresh() {
        const response = await requestService.fetch({
            AssertValues: true,
            Query: (`
        SELECT
          df.DetectionId,
          df.DetectionDeviceId,
          df.DetectionFinalTime,
          df.Digital, df.Digital as keyDigital,
          df.TimeLimitedParking, df.TimeLimitedParking as keyTimeLimitedParking,
          df.IllegallyParked, df.IllegallyParked as keyIllegallyParked,
          df.ParkingRight, df.ParkingRight as keyParkingRight,
          df.Verification, df.Verification as keyVerification,
          df.DetectionState, df.DetectionState as keyDetectionState,
          ST_AsGeoJSON(geo.VehicleBounds) as VehicleBounds
        FROM detections_geo_bounds_original geo
        INNER JOIN detections_final df USING (DetectionId, DetectionDeviceId)
        WHERE DetectionTime > :PageLoadTime AND DetectionFinalTime > :LastVerificationTime
      `),
            Params: {
                PageLoadTime: this.pageLoadTime.toJSON(),
                LastVerificationTime: this.lastVerificationTime.toJSON()
            }
        });
        if (response.isEmpty) {
            return;
        }
        const dftIndex = response.Columns.indexOf('DetectionFinalTime');
        response.Rows.map((detection) => {
            const verificationTime = new Date(detection[dftIndex]);
            if (this.lastVerificationTime < verificationTime) {
                this.lastVerificationTime = verificationTime;
            }
            console.log({ LastVerificationTime: verificationTime });
        });
        const newAndChangedMarkers = ShowMapScans({
            response,
            map: null, // Map reference
        });
        const markersToReplace = newAndChangedMarkers.filter(m => {
            return this.markersMap.hasOwnProperty(m.get('Id'));
        });
        const markersToInsert = newAndChangedMarkers.filter(m => {
            return !this.markersMap.hasOwnProperty(m.get('Id'));
        });
        markersToReplace.map(m => {
            const markerToReplace = this.markersMap[m.get('Id')];
            markerToReplace.setMap(null);
            const markerIndex = this.markers.indexOf(markerToReplace);
            if (markerIndex !== -1) {
                this.markers.splice(markerIndex, 1);
            }
            this.markersMap[m.get('Id')] = m;
            m.setMap(this.map);
            this.markers.push(m);
        });
        markersToInsert.map(m => {
            this.markersMap[m.get('Id')] = m;
            m.setOptions({ fillOpacity: 0.0, strokeOpacity: 0.0 });
            m.setMap(this.map);
            this.markers.push(m);
            this.fadeInMarkers.push(m);
        });
        // markersToInsert.map((marker) => {
        //   marker.setOptions({ fillOpacity: 0.0, strokeOpacity: 0.0 })
        // })
        // this.fadeInMarkers.push(...markersToInsert as APolygon[])
        // this.markers.push(...markersToInsert)
    }
}
export function css() {
    return ( /*html*/`
    <style>
      .views {
        height: calc(100% - 102px);
      }

      .kpis .kpi-block-list > .column {
        margin-bottom: var(--u1);
      }
    </style>
  `);
}
export function render() {
    return ( /*html*/`
		<div class="kpis" style="position: relative; background: #f1f0f0; height: 100%">
			<div class="wrapper postscan lg columns col-gapless">
				<div class="column col-4 custom-scroll" style="height: 100%">

          <div class="aci-tabs tabs-flex tabs-sticky tabs-fixed-md tabs-xl v-zero sharp-border" tabgroup="sideview">
            <button style="flex: 0 1 50%;" class="aci-tab active" tab="device-list">
              <i class="fa-solid fa-car-wrench"></i>
              <span>Device List</span>
            </button>
            <button style="flex: 0 1 50%;" class="aci-tab" tab="device-chart">
              <i class="fa-solid fa-route"></i>
              <span>Device Charts</span>
            </button>
          </div>
          
          <div class="views" style="overflow-y: auto">
            <div class="column col-12 mt-1" tabgroup="sideview" tabview="device-list">
              <div id="firstPanel" class="list-container" style="overflow-y: auto;">
                <table class="styled-table grid-like fw">
                  <thead>
                    <tr class="head noselect">
                      <th>Device</th>
                      <th>User</th>
                      <th>Last Updated</th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                </table>
              </div>
            </div>
            <div class="column col-12 mt-1" tabgroup="sideview" tabview="device-chart">
              <div class="columns kpi-block-list">
        
              </div>
            </div>
          </div>
          
          <div id="lastPanel" class="list-container" style="background: #f8f9fa; height: 45px">
            <div id="count" class="text spacing01">
              Viewing
              <span>0</span>
              Devices
            </div>
          </div>
				</div>
				<div class="column col-8" style="height: 100%; background: #f8f9fa">
					<div id="map" class="aci-map"></div>
				</div>
			</div>
		</div>
	`);
}
