import { AEngine, sleep } from '../core/AEngine.js';
import { APreferenceService, APrefs } from './APreferenceService.js';
import { AMapOverlayService } from './AMapOverlayService.js';
import { AJsonService } from './AJsonService.js';
import { EVENTS } from './AEventService.js';
import { AError } from '../classes/AError.js';
import { ACrypto } from '../classes/ACrypto.js';
import { AUnitTestService } from './AUnitTestService.js';
import { APopoverService } from './APopoverService.js';
import { createArray } from '../utils/tools.js';
import { AMapHelperService } from './AMapHelperService.js';
export class AMenuService {
    get availableUrls() {
        const availableUrls = [];
        for (let item of this.menu) {
            const menuIdMr = item.id_mr ? [item.id_mr] : [];
            if (item.hidden === true || !permissionService.hasPermission(menuIdMr)) {
                continue;
            }
            const childLinks = (item.children || [])
                .filter(c => permissionService.hasPermission(menuIdMr.concat([c.id_mr]).filter(v => v !== undefined)))
                .filter(c => c.hidden !== true)
                .map(c => c.url);
            const urlsToPush = (childLinks.length > 0) ? childLinks : [item.url];
            availableUrls.push(...urlsToPush);
        }
        return availableUrls;
    }
    constructor() {
    }
    async autoInit() {
        this.mapOverlayService = AEngine.get(AMapOverlayService);
        this.preferenceService = AEngine.get(APreferenceService);
        this.startMenuOpened = this.preferenceService.load(APrefs.MENU_OPEN, null);
        Events.h_once(EVENTS.PREFETCH, _ => this.prefetch());
        Events.hardwire(EVENTS.PAGE_INITIALIZED, () => {
            this.updateMenuHighlights();
            $('.sidebar-menu').toggleClass('no-select', false);
        });
        Events.hardwire(EVENTS.ROUTE_CHANGED, () => {
            this.updateMenuHighlights();
            $('.sidebar-menu').toggleClass('no-select', true);
        });
        this.initHeaderStrip();
        this.initAccountPopover();
        this.createMouseHandlers().catch(AError.handle);
        Events.hardwire(EVENTS.STATE_CHANGED, ({ state, prevState }) => {
            this.updateRequireAuthElements();
        });
        this.updateRequireAuthElements();
    }
    updateRequireAuthElements() {
        let isLoggedIn = stateService.isLoggedIn();
        $('[aci-require-auth]').toArray().map(e => $(e)).map($e => {
            const requireAuth = $e.attr('aci-require-auth') === 'true';
            if (requireAuth) {
                $e.toggleClass('hidden', !isLoggedIn);
            }
            else {
                $e.toggleClass('hidden', false);
            }
        });
    }
    updateMenuHighlights() {
        const { url } = routeService.meta.menuItem;
        $('.menu-links li.active').removeClass('active');
        $(`.menu-links a[href="#!${url}"]`).closest('li').addClass('active');
        $(`.menu-links [ref="menu"] a[href="${url}"]`).closest('li').prev().addClass('active');
        $(`.popover-container .popover-open a[href="#!${url}"]`).closest('li').addClass('active');
    }
    isCurrentPage(href) {
        const { url } = routeService.meta.menuItem;
        return url === href;
    }
    getMenuOptionsForUrl(hash) {
        if (this.menuFlat.hasOwnProperty(hash)) {
            return this.menuFlat[hash];
        }
        if (hash !== '/') {
            AError.handle({
                useModal: false,
                err: [new Error(`Couldn't find the menu item for hash: "${hash}" `)],
            });
        }
        return null;
    }
    initHeaderStrip() {
        $('.header-strip [href]').on('click', e => e.preventDefault());
        $('.header-strip [action="TOGGLE_LANGUAGE"]').on('click', _ => this.toggleLanguageMenu());
    }
    get $content() {
        return $('#AjaxContent');
    }
    get isMenuOpen() {
        const $menu = $('.sidebar-menu');
        return $menu.hasClass('menu-open');
    }
    async fetchMenu(opt) {
        const menu = jsonService.getFromCache({ url: AJsonService.JSON_FILES.MENU });
        if (opt.translate) {
            const titles = new Set(menu.map(r => [r.title, ...(r.children ?? []).map(c => c.title)]).flat());
            const t = await Translate.get([...titles]);
            for (let item of menu) {
                item.title = t[item.title];
                item?.children?.map(c => c.title = t[c.title]);
            }
        }
        for (let item of menu) {
            if (item.premium === true && item.children) {
                item.children.map((child) => {
                    child.premium = true;
                });
            }
        }
        return menu;
    }
    toggleLanguageMenu() {
        if (!globalThis.AllowLanguageChange) {
            return;
        }
        const $frame = $('#LanguageFrame');
        if ($frame.hasClass('visible')) {
            $frame.removeClass('visible');
        }
        else {
            $('#SearchLanguage').trigger('focus');
            $frame.addClass('visible');
        }
    }
    async manualInit(opt) {
        // Menu isn't being translated on dev mode, so we have to do it manually
        this.menu = await this.fetchMenu(opt);
        window['MenuAjax'] = this.menu;
        this.generateMenuFlat(this.menu);
    }
    async prefetch() {
        if (AEngine.isDevelopmentMode) {
            await this.manualInit({ translate: true });
        }
        const menuHtml = this.generateMenu(this.menu);
        $('.sidebar-menu ul').html(menuHtml);
        this.restoreSideMenuState().catch(AError.handle);
        this.updateCssContainerSizing();
        AEngine.get(APopoverService).initMenuPopovers();
        AEngine.get(AMapHelperService).initMapRightClick();
        return this;
    }
    initAccountPopover() {
        $('.header-strip [action="TIPS"]').on('click', async (e) => {
            try {
                e.preventDefault();
                const ps = PageScript;
                if (ps.showTutorial === undefined) {
                    return;
                }
                const tips = await ps.showTutorial();
                for (let tip of tips) {
                    await tip.exec();
                }
            }
            catch (err) {
                AError.handle(err);
            }
        });
        $('.header-strip [action="TOGGLE_MENU"]').on('click', e => {
            e.preventDefault();
            this.toggleMenu();
        });
        $('#popover-account a[href="#"][action]').on('click', (e) => {
            e.preventDefault();
        });
        $('#popover-account [action="SIGNOUT"]').on('click', e => {
            location.reload();
        });
        $('#popover-account [action="SESSIONS"]').on('click', e => {
            nodeSessionService.showDialog('#AjaxContent');
        });
        $('#popover-account [action="UNIT_TESTING"]').on('click', e => {
            AEngine.get(AUnitTestService).showDialog();
        });
        $('#popover-account [action="NOTIMPL"]').on('click', e => {
            e.preventDefault();
            Alerts.notImplementedYet();
        });
    }
    setVisible($p, visible) {
        $p.toggleClass('popover-open', visible);
    }
    async restoreSideMenuState() {
        switch (this.startMenuOpened) {
            case null:
                break;
            case true:
                if (!this.isMenuOpen) {
                    this.toggleMenu();
                }
                break;
            case false:
                if (this.isMenuOpen) {
                    this.toggleMenu();
                }
                break;
        }
    }
    async createMouseHandlers() {
        $(document).on('mouseenter', '#sidebar-popover[ref]', (e) => {
            const $popover = $(e.target).is('.sidebar-popover') ? $(e.target) : $(e.target).closest('.sidebar-popover');
            this.setVisible($popover, true);
        });
        $(document).on('mouseleave', '#sidebar-popover[ref]', (e) => {
            const $popover = $(e.target).is('.sidebar-popover') ? $(e.target) : $(e.target).closest('.sidebar-popover');
            this.setVisible($popover, false);
        });
        $(document).on('mouseenter', '#menu-account,#popover-account', (e) => this.setVisible($('#popover-account'), true));
        $(document).on('mouseleave', '#menu-account,#popover-account', (e) => this.setVisible($('#popover-account'), false));
    }
    toggleMenu(desiredOpen) {
        const $body = $('body');
        const $menu = $('.sidebar-menu');
        const menuClosed = $menu.hasClass('menu-open');
        if (menuClosed && (desiredOpen === false || desiredOpen === undefined)) {
            $menu.removeClass('menu-slide-in');
            $menu.removeClass('menu-open');
            this.$content.css('margin-left', 'var(--sidebar-width)');
            this.$content.css('width', 'calc(100% - var(--sidebar-width) - var(--sidebar-margin-right))');
            $body.toggleClass('aci-sidebar-open', false);
        }
        if (!menuClosed && (desiredOpen === true || desiredOpen === undefined)) {
            $menu.addClass('menu-slide-in');
            $menu.addClass('menu-open');
            this.$content.css('margin-left', 'var(--sidebar-open-width)');
            this.$content.css('width', 'calc(100% - var(--sidebar-open-width) - var(--sidebar-margin-right))');
            $body.toggleClass('aci-sidebar-open', true);
        }
        sleep(600).then(_ => Events.tryInvoke(EVENTS.CONTENT_RESIZE, { caller: 'AMenuService' }));
        this.preferenceService.save(APrefs.MENU_OPEN, this.isMenuOpen);
        return menuClosed;
    }
    updateCssContainerSizing() {
        const $menu = $('.sidebar-menu');
        const menuOpen = $menu.hasClass('menu-open');
        if (menuOpen) {
            this.$content.css('margin-left', 'var(--sidebar-open-width)');
            this.$content.css('width', 'calc(100% - var(--sidebar-open-width) - var(--sidebar-margin-right))');
        }
        else {
            this.$content.css('margin-left', 'var(--sidebar-width)');
            this.$content.css('width', 'calc(100% - var(--sidebar-width) - var(--sidebar-margin-right))');
        }
    }
    generateMenu(menu) {
        // TODO: Check if permissions logic require changes
        // if (AEngine.isDevelopmentMode) {
        //   AEngine.warn(`// TODO: Check if permissions require checking`)
        // }
        const menuItems = [];
        for (let item of menu) {
            if (item.hidden === true) {
                continue;
            }
            const menuIdMr = item.id_mr ? [item.id_mr] : [];
            if (!permissionService.hasPermission(menuIdMr)) {
                continue;
            }
            const className = item.children ? ` class="menu-dropdown"` : '';
            if (!item.url && item.children && item.children.length) {
                item.url = item.children[0].url;
            }
            const childLinks = (item.children || [])
                .filter(c => permissionService.hasPermission(menuIdMr.concat([c.id_mr]).filter(v => v !== undefined)))
                .filter(c => c.hidden !== true);
            const linkHtml = (childLinks.length) ?
                (`<a href="#!${childLinks[0].url}">${item.title}</a>`) :
                (`<a href="#!${item.url}">${item.title}</a>`);
            menuItems.push(`
        <li ${className}>
          <i class="${item.icon}"></i>
          ${linkHtml}
          <div class="expand"><i class="fa-solid fa-chevron-right fa-xs"></i></div>
        </li>
      `);
            if (childLinks.length > 0) {
                menuItems.push(`<li ref="menu" class="hidden">${childLinks.map(c => `<a href="${c.url}">${c.title}</a>`).join('')}</li>`);
            }
        }
        return menuItems.join('');
    }
    generateMenuFlat(menu) {
        let menuFlat = {};
        $.each(menu, function (_, mv) {
            if (mv.children != undefined) {
                $.each(mv.children, function (_, smv) {
                    menuFlat[smv.url] = Object.assign({}, {
                        id_mr: smv.id_mr,
                        title: mv.title + " - " + smv.title,
                        usergroups: smv.usergroups,
                        url: smv.url,
                        parent: mv,
                        obsolete: (smv.obsolete === true),
                        bypass_login: false
                    });
                });
            }
            else {
                menuFlat[mv.url] = Object.assign({}, {
                    id_mr: mv.id_mr,
                    title: mv.title,
                    usergroups: mv.usergroups,
                    url: mv.url,
                    hidden: mv.hidden,
                    bypass_login: mv.bypass_login || false
                });
            }
        });
        this.menuFlat = menuFlat;
        return menuFlat;
    }
    async addMapButton(options) {
        const { map, mapElement, uid, title, titleTag, icon, order, position, tag } = Object.assign({ uid: ACrypto.randomHexString(10), title: '', tag: 'div' }, options);
        const titleText = title ? await Translate.get(title) : '';
        const $ele = $(`
      <${tag} id="${uid}" class="poly-toggle scale-toggle">
        <div class="noselect ns-children">
          <label class="form-label">
            ${icon !== undefined ? `<i class="${icon} fa-fw"></i>` : ''}
            ${titleTag ? `<${titleTag}>${titleText}</${titleTag}>` : titleText}
          </label>
        </div>
      </${tag}>
    `);
        this.mapOverlayService.add(map, mapElement, $ele, position, { uid, order });
        return $ele;
    }
    async addMapButtonRadio(options) {
        const { map, mapElement, titles, icons, order, position } = options;
        if (titles.length === 0)
            return [];
        const $btns = await Promise.all(titles.map((title, i) => this.addMapButton({
            map,
            mapElement,
            title,
            titleTag: 'span',
            icon: icons[i],
            position,
            order: (order + i * 0.01),
        })));
        $btns[0].addClass('text-bold');
        $btns.map(($btn, i) => {
            $btn.on('click', () => {
                $btns.map($b => $b.toggleClass('text-bold', $b.is($btn)));
            });
        });
        $btns.map(($btn, i) => {
            let cls = (i !== 0) ?
                ((i !== $btns.length - 1) ? '' : 'map-btn-right') : 'map-btn-left';
            $btn.addClass(`map-btn-inline ${cls}`);
        });
        return $btns;
    }
    async addPopover($btnArray, options) {
        const { uid, trigger, $relativeParent } = Object.assign({ uid: idAllocatorService.getNextId({ prefix: 'hvr' }) }, options);
        const popoverSelector = `.sidebar-popover[uid="${uid}"]`;
        const $popover = $(`<div id="popover-generic" class="sidebar-popover" uid="${uid}"><ul></ul></div>`);
        const $btnArr = ($btnArray.length === 0) ? createArray(0).map((_, i) => $(/*html*/ `<a><div class="noselect ns-children">Test ${i + 1}</div></a>`)) : $btnArray;
        $btnArr.map($btn => $popover.find('ul').append($(`<li></li>`).append($btn.on('click', (e) => this.setVisible($(`${popoverSelector}`), false)))));
        $relativeParent.append($popover);
        const onEnable = (e) => {
            const $p = $(`${popoverSelector}`), $btn = $(`#${uid}`);
            let bo = ($btn.offset() || { left: 0, top: 0 });
            $p.css({
                right: innerWidth - bo.left - ($btn.innerWidth() || 0),
                top: bo.top + ($btn.height() || 0)
            });
            this.setVisible($p, true);
        };
        if (trigger === 'hover') {
            $(document).on('mouseenter', `${popoverSelector},#${uid}`, (e) => onEnable(e));
        }
        else {
            $(document).on('click', `#${uid}`, (e) => onEnable(e));
            $(document).on('mouseenter', `${popoverSelector}`, (e) => onEnable(e));
        }
        $(document).on('mouseleave', `${popoverSelector},#${uid}`, (e) => { this.setVisible($(`${popoverSelector}`), false); });
        const destroy = () => {
            $(document).off('click', `#${uid}`);
            $(document).off('mouseenter', `${popoverSelector},#${uid}`);
            $(document).off('mouseleave', `${popoverSelector},#${uid}`);
            $(document).off('mouseenter', `${popoverSelector}`);
            $popover.remove();
        };
        Events.on(EVENTS.DESTRUCT, () => destroy());
        return {
            $ele: $(`#${uid}`),
            destroy: () => destroy(),
        };
    }
    async addMapDropdown(arrayOf$ele, options) {
        const { map, mapElement, uid, title, icon, order, position } = Object.assign({ uid: idAllocatorService.getNextId(), title: '' }, options);
        const $mapBtn = this.addMapButton({ map, mapElement, uid, title, icon, order, position });
        const popoverSelector = `.sidebar-popover[uid="${uid}"]`;
        const $popover = $(`<div class="sidebar-popover popover-map c-scroll" uid="${uid}"><ul></ul></div>`);
        const $ul = $popover.find('ul');
        let i = 0;
        for (let $ele of arrayOf$ele) {
            $(`<li></li>`).append($ele).appendTo($ul);
            if (options.seperatorIndices?.includes(i)) {
                $ul.append(/*html*/ `<li class="separator"></li>`);
            }
            i++;
        }
        const $parent = $(mapElement).append($popover);
        let eventId;
        $(document).on('mouseenter', `${popoverSelector},#${uid}`, (e) => {
            const $p = $(`${popoverSelector}`), $btn = $(`#${uid}`);
            let mp = ($parent.offset() || { left: 0, top: 0 });
            let bo = ($btn.offset() || { left: 0, top: 0 });
            $p.css({
                left: bo.left - mp.left,
                top: bo.top - mp.top + ($btn.outerHeight() || 0) + 8
            });
            this.correctOutOfBounds({ $popover: $p, $parent });
            this.setVisible($p, true);
            clearTimeout(eventId);
            eventId = undefined;
        });
        $(document).on('mouseleave', `${popoverSelector},#${uid}`, (e) => {
            if (eventId) {
                clearTimeout(eventId);
                eventId = undefined;
            }
            eventId = setTimeout(() => {
                this.setVisible($(`${popoverSelector}`), false);
            }, 160);
        });
        Events.on(EVENTS.DESTRUCT, () => {
            $(document).off('mouseenter', `${popoverSelector},#${uid}`);
            $(document).off('mouseleave', `${popoverSelector},#${uid}`);
            $popover.remove();
        });
        return $mapBtn;
    }
    correctOutOfBounds(opt) {
        let { $popover: $p, $parent = $('body') } = opt;
        let { left, top } = $p.position();
        let ulHeight = $p.find('ul').height();
        let h = $p.outerHeight();
        let dh = h - $p.height();
        let ph = $parent.height();
        // let w = $p.outerWidth()! as number
        // let dw = w - $p.height()!
        // let pw = $parent.width()! as number
        // console.log(top + h, ' -> ', top + (dh) + ulHeight)
        $p.css('max-height', (top + dh + ulHeight > ph) ? (ph - dh - top) : '');
        // if (left + w > pw) {}
    }
}
