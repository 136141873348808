import { AShowTable, formatStringToPascal } from "../utils/tools.js";
import { EVENTS } from "./AEventService.js";
import { AEngine } from "../core/AEngine.js";
import { ATimeService } from "./ATimeService.js";
import { ANodeSessionTracker } from "../classes/session/ANodeSessionTracker.js";
import { COLUMN_ACTION } from "../classes/AGridTypes.js";
export class ANodeSessionService {
    constructor() {
        this.SessionData = {};
        this.trackers = [];
        this.timeService = AEngine.get(ATimeService);
    }
    async autoInit() {
        Events.hardwire('SessionsChanged', _ => {
            for (let session of Sessions)
                this.SessionData[session.NodeName] = session;
            this.trackers.map(tracker => tracker.onDataReceived(this.SessionData));
            Events.tryInvoke(EVENTS.SESSION_UPDATE_DATA, this.SessionData);
        });
        for (let session of Sessions)
            this.SessionData[session.NodeName] = session;
        this.trackers.map(tracker => tracker.onDataReceived(this.SessionData));
        Events.tryInvoke(EVENTS.SESSION_UPDATE_DATA, this.SessionData);
    }
    /**
     * Display other connected devices on the map
     */
    bindSessionsToMap(opt) {
        const tracker = new ANodeSessionTracker(opt);
        tracker.init(this.SessionData);
        this.trackers.push(tracker);
        return opt.mapMarkers;
    }
    async sessionToObj(NodeName) {
        const s = this.SessionData[NodeName];
        if (s.Gps == null) {
            debugger;
        }
        const [timeAgo, NodeNameTranslated] = await Promise.all([
            s.Gps ? this.timeService.ago(new Date(), new Date(s.Gps?.GpsTime ?? Date.now())) : '',
            Translate.get(NodeName)
        ]);
        return {
            'Device': NodeNameTranslated,
            'User': s.UserDisplayName || s.User,
            'StatusString': s.StatusString,
            'Status': s.Status,
            'ComState': s.ComState,
            'LastUpdated': timeAgo,
        };
    }
    async showDialog(parent = 'body') {
        const window = await purgatoryService.showInfoWindowSingleCustom({
            nest: parent,
            parent: parent,
            table: (`<div id="table-bryntum-sessions" class="fw fh" style="min-width: 900px"></div>`),
            options: { height: '500px' },
            hardwire: true,
        });
        const columns = [
            { field: 'Device', text: '' },
            {
                field: 'Status',
                text: '',
                htmlEncode: false,
                renderer: (opt) => {
                    const style = opt.record.ValidLogin ? "--fa-animation-duration: 1s; --fa-beat-scale: 0.8;" : '';
                    return ( /*html*/`
            <div class="menu-label children-inline-block">
              <span class="statusbar ${opt.record.Status}">
                <i class="fa-solid fa-circle fa-beat" style="${style}"></i>
                <span>${formatStringToPascal(opt.record.StatusString)}</span>
              </span>
            </div>
          `);
                }
            },
            { field: 'User', text: '' },
            { field: 'LastUpdated', text: '' },
            { field: 'StatusString', text: '', hidden: true },
            {
                field: 'ComState',
                text: '',
                ...COLUMN_ACTION({ iconCls: 'fa-regular fa-trash text-red', btnCls: 'btn-white' }),
            },
        ];
        const t = await Translate.get(columns.map(c => c.field));
        columns.map(c => c.text = t[c.field]);
        const data = await Loading.waitForPromises(Object.keys(this.SessionData).map((NodeName) => this.sessionToObj(NodeName)));
        this.grid = AShowTable({
            appendTo: 'table-bryntum-sessions',
            aci: {
                resizeToFit: true,
                resizeToFitReverse: true
            },
            columns: columns,
            data: data
        });
        // Update targets
        Events.hardwire(EVENTS.SESSION_UPDATE_DATA, async () => {
            this.grid.data = await Loading.waitForPromises(Object.keys(this.SessionData).map((NodeName) => this.sessionToObj(NodeName)));
        });
        return window;
    }
}
