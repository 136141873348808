import { AError } from "../classes/AError.js";
import { AInfoWindowHelper } from "../classes/AInfoWindowHelper.js";
import { ATableBuilder } from "../core/form/table/ATableBuilder.js";
import { ATableFormatter } from "../core/form/table/ATableFormatter.js";
import { getCenterAny, getPolygonCenter, _getEle$ } from "../utils/maps.js";
import { DetectionsFullSingle } from "../utils/query.js";
import { AFormatDate } from "../utils/tools.js";
import { EVENTS } from "./AEventService.js";
import { default_order, default_tableformatter, geoLayers_tableformatter, team_sorting, team_tableformatter } from "../utils/table_formatter.js";
import { AEngine } from "../core/AEngine.js";
import { AResponse } from "../classes/AResponse.js";
import { initTableAccordions } from "../utils/table_accordions.js";
import { ATableKey } from "../core/form/table/ATableKey.js";
import { AConfig } from "../classes/AConfig.js";
const PRIORITY_ID = 'TableSummary';
const FULLTABLE_ID = 'TableFull';
export var InfoWindowType;
(function (InfoWindowType) {
    InfoWindowType[InfoWindowType["custom"] = 0] = "custom";
    InfoWindowType[InfoWindowType["google"] = 1] = "google";
})(InfoWindowType || (InfoWindowType = {}));
export class APurgatoryService {
    createDoubleTableRes({ response, tableFormatter, greyOutFields }) {
        const TableBuilderPriority = new ATableBuilder({ tableId: PRIORITY_ID, tableFormatter, greyOutFields });
        const TableBuilder = new ATableBuilder({ tableId: FULLTABLE_ID, tableFormatter, greyOutFields });
        for (let c = 0; c < response.Columns.length; c++) {
            const column = response.Columns[c];
            const columnTranslated = response.ColumnsTranslated[c];
            const tableKey = new ATableKey(column, columnTranslated);
            const columnOptions = tableFormatter.getColumnFormatOptions(tableKey); // InfoWindowHelper.getColumnSettings(column)
            const insertVal = [tableKey, response.Rows[0][c]];
            if (columnOptions.isPriority === true) {
                TableBuilderPriority.insert(insertVal);
            }
            TableBuilder.insert(insertVal);
        }
        return [TableBuilderPriority, TableBuilder];
    }
    /**
     * @TWO_EX
     * CREATES TWO TABLES (2 OBJECT EXPORT)
     * Infowindow with "extra information" button
     */
    async createDoubleTableObj(verifyRequest) {
        // const fieldDefinitions = AConfig.get('infoWindows.default.definitions', {})
        // const sorting = AConfig.get('infoWindows.default.order', [])
        const tableFormatter = default_tableformatter();
        const TableBuilderPriority = new ATableBuilder({ tableId: PRIORITY_ID, tableFormatter, greyOutFields: false });
        const TableBuilder = new ATableBuilder({ tableId: FULLTABLE_ID, tableFormatter, greyOutFields: false });
        const keys = Object.keys(verifyRequest);
        const translations = await Loading.waitForPromises(Translate.get(keys));
        for (let column of keys) {
            const tableKey = new ATableKey(column, translations[column]);
            const columnOptions = tableFormatter.getColumnFormatOptions(tableKey); //InfoWindowHelper.getColumnSettings(column)
            const insertVal = [tableKey, verifyRequest[column]];
            if (columnOptions.isPriority === true) {
                TableBuilderPriority.insert(insertVal);
            }
            TableBuilder.insert(insertVal);
        }
        return { TableBuilderPriority, TableBuilder };
    }
    async createSingleTable(options) {
        const opt = Object.assign({
            tableId: FULLTABLE_ID, greyOutFields: true
        }, options);
        const builder = new ATableBuilder(opt);
        const keys = Object.keys(opt.data);
        const translations = await Loading.waitForPromises(Translate.get(keys));
        for (let column of keys) {
            const insertVal = [new ATableKey(column, translations[column]), opt.data[column]];
            builder.insert(insertVal);
        }
        return builder;
    }
    /**
    * @ONE_EX_STR
    * @where [ADetectionService.findHistory()]
    */
    responseToTable(response, options) {
        const { index, excluded } = Object.assign({ excluded: [] }, options);
        let { Rows, Columns, ColumnsTranslated } = response;
        let output = [];
        if (index !== null && index !== undefined) {
            Rows = [Rows[index]];
        }
        for (let i = 0; i < Rows.length; i++) {
            const TableBuilder = new ATableBuilder();
            for (let c = 0; c < Columns.length; c++) {
                if (excluded.includes(Columns[c])) {
                    continue;
                }
                const insertVal = [new ATableKey(Columns[c], ColumnsTranslated[c]), Rows[i][c]];
                TableBuilder.insert(insertVal);
            }
            output.push(TableBuilder);
        }
        return output.map((tb, i) => tb.build({ visible: (i === 0) })).join('');
    }
    revertCachedMarkerColor(marker) {
        if (!PageScript)
            return;
        const { __cachedMarker, __cachedColors } = PageScript;
        if (!__cachedMarker || !__cachedColors)
            return;
        if (__cachedMarker !== marker) {
            __cachedMarker.setOptions({
                fillOpacity: 0.35
            });
            delete PageScript.__cachedMarker;
            delete PageScript.__cachedColors;
        }
    }
    cacheMarkerColor(marker) {
        PageScript.__cachedMarker = marker;
        PageScript.__cachedColors = {
            fill: marker.fillColor,
            outline: marker.strokeColor
        };
    }
    applySelectedColor(marker) {
        try {
            // purgatoryService.revertCachedMarkerColor(marker)
            purgatoryService.closeInfoWindow();
            purgatoryService.cacheMarkerColor(marker);
            marker.setOptions({ fillOpacity: 1 });
        }
        catch (err) {
            AError.handle(err);
        }
    }
    /**
     * @TWO
     * CREATES 2 TABLES AND DISPLAYS
     * @where [SCANS_CENTRALVERIFICATION_ADVANCEDSCANS_GPSSTATS_SCANSROUTE_SCANSUNIQUE_SEARCH]
     * @param {*} marker
     */
    async onMarkerClickRealtime(marker, args) {
        AEngine.log('args', args);
        if (this instanceof google.maps.Polygon) {
            marker = this;
        }
        try {
            purgatoryService.applySelectedColor(marker);
        }
        catch (err) {
            console.error(err);
        }
        const { DetectionDeviceId, DetectionId } = marker.get('PK');
        // TODO: Move logic to Query.js
        try {
            return await Loading.waitForPromises(requestService.query({
                Query: (`SELECT * FROM (${DetectionsFullSingle}) d`),
                Params: {
                    DetectionId,
                    DetectionDeviceId
                },
                Language: Language,
                Translate: [
                    "VehicleType",
                    "ParkingAreaType",
                    "DeviceName",
                    "ParkingRightType",
                    "CardinalDirection",
                    "DetectionDevice",
                    "VerificationDevice",
                    "Weekday",
                    "Month",
                    "Week",
                    "Visitor",
                    "Digital",
                    "TimeLimitedParking",
                    "IllegallyParked",
                    "ParkingRight",
                    "Verification",
                    "DetectionState",
                    "VerificationChannel"
                ]
            }).then(async (response) => {
                const infop = marker.get('infoParent');
                let stretchOut = (args?.stretchOut || infop !== undefined);
                const $iwcontainer = infop ? _getEle$(infop) : ($('#modal-map').length > 0 ? $('#modal-map') : $('.aci-map').eq(0));
                // Change position of the streetview
                const map = marker.getMap();
                if (map != null) {
                    const pano = map.getStreetView();
                    const detectionLatLng = getPolygonCenter(marker);
                    pano.setPosition(detectionLatLng);
                    setTimeout(_ => pano.setPov({
                        pitch: 1,
                        heading: google.maps.geometry.spherical.computeHeading(pano.getPosition(), detectionLatLng)
                    }), 80);
                }
                if (AEngine.isDevelopmentMode) {
                    AEngine.log('marker data', new AResponse(response).toArray());
                }
                purgatoryService.buildAndShowInfoWindowCustom({
                    nest: stretchOut ? $iwcontainer : undefined,
                    parent: $iwcontainer,
                    data: response,
                    marker: marker,
                    greyOutFields: true,
                    sorting: default_order(),
                    tableFormatter: default_tableformatter(),
                });
                return response;
            }));
        }
        catch (err) {
            return AError.handle(err);
        }
    }
    /**
    * Shows the InfoWindow for the clicked Team member
    */
    clickTeamMember() {
        const marker = this;
        // @ts-ignore
        const { data } = marker;
        if (!data)
            return;
        console.log('marker', marker);
        console.log('data', data);
        if (data && data.Gps && data.Gps.GpsTime) {
            Object.assign(data, {
                LastUpdated: AFormatDate(new Date(data.Gps.GpsTime))
            });
        }
        return purgatoryService.buildAndShowInfoWindowLegacy({
            data: data,
            marker: this,
            sorting: team_sorting(),
            tableFormatter: team_tableformatter(),
        });
    }
    /**
     * Shows the InfoWindow for the clicked polygon
     */
    async clickPolygon(map) {
        // "This" is in the context of a marker
        // @ts-ignore
        if (!this.data)
            return;
        // @ts-ignore
        console.log('this', this, 'data', this.data);
        // @ts-ignore
        const data = this.data;
        // TODO: Remove area?
        const { scale, area, id, geoType } = data;
        const geoObjects = mapHelperService.geoObjectMapper(scale);
        if (!geoObjects) {
            throw new Error(`No map scale selected!`);
        }
        let copied = Object.assign({}, geoObjects[id]);
        let windowOptions = {};
        if (PageScript.overridePolygonInfoWindow) {
            windowOptions = PageScript.overridePolygonInfoWindow({ ref: this, copied, data });
            purgatoryService.buildAndShowInfoWindowLegacy({
                map,
                marker: this,
                data: (windowOptions.combineResults === true) ? { ...windowOptions.data, ...copied } : windowOptions.data,
                tableFormatter: (windowOptions.combineResults === true) ? new ATableFormatter({
                    ...geoLayers_tableformatter().fieldDefinitions,
                    ...windowOptions.tableFormatter.fieldDefinitions,
                }) : windowOptions.tableFormatter,
                greyOutFields: true,
                sorting: Object.keys(windowOptions.data),
            });
        }
        else {
            const tableFormatter = geoLayers_tableformatter({
                override: {
                    'Geo': { type: 'HIDDEN' },
                    'Attributes': { expanded: true },
                }
            });
            const sorting = Object.keys(tableFormatter.fieldDefinitions);
            purgatoryService.buildAndShowInfoWindowLegacy({
                map,
                pos: getCenterAny(this),
                // marker: (this as AMarker).getPosition(),
                data: copied,
                tableFormatter: tableFormatter,
                greyOutFields: true,
                sorting: sorting,
            });
        }
    }
    async buildAndShowInfoWindowLegacy({ map, marker, pos, data, sorting, tableFormatter, greyOutFields = false }) {
        const table = await this.createSingleTable({
            data: data,
            tableFormatter: tableFormatter,
            greyOutFields
        });
        const iw = this.showInfoWindowLegacy({
            map: map,
            // marker: marker,
            pos: pos ?? getCenterAny(marker),
            content: table.build({ sorting }),
            // closeInfoWindow: false
        });
        this.focusOnMarker(marker);
        return iw;
    }
    async buildAndShowInfoWindow({ parent, marker, data, sorting, tableFormatter, greyOutFields = false }) {
        const table = await this.createSingleTable({
            data: data,
            tableFormatter: tableFormatter || new ATableFormatter(),
            greyOutFields
        });
        const iw = await this.showInfoWindowSingleCustom({
            parent: parent || '.aci-map',
            table: table.build({ sorting }),
            options: { buttons: [] }
        });
        this.focusOnMarker(marker);
        return iw;
    }
    async buildAndShowInfoWindowCustom(opt) {
        const { parent, nest, marker, data, sorting, tableFormatter, greyOutFields = false } = opt;
        const tables = this.createDoubleTableRes({
            response: data,
            tableFormatter: tableFormatter || new ATableFormatter(),
            greyOutFields
        }).map(t => t.build({ sorting }));
        const iw = await this.showInfoWindowDoubleCustom({
            parent: parent || '.aci-map',
            nest: nest,
            tables: tables,
            options: {
                buttons: [{
                        id: 'info-history',
                        text: 'History'
                    }]
            }
        });
        iw.elements.map($ele => $ele.on('click', _ => {
            marker.get('Id').split('_');
            // let [DetectionDeviceId, DetectionId] = (marker as any).Identifier.split('_')
            const primaryKey = marker.get('PK');
            detectionService.findHistory(primaryKey);
        }));
        this.focusOnMarker(marker);
        return iw;
    }
    async showInfoWindowSingleCustom({ parent, nest, table, hardwire, options }) {
        await purgatoryService.closeInfoWindow();
        const $parent = _getEle$(parent);
        // console.warn({ parent, $parent, nest, $nest: _getEle$(nest ?? '#AjaxContent') })
        const { buttons, height } = Object.assign({ buttons: [] }, options);
        const elementsToFind = ['#info-toggle-data'];
        buttons.map((button) => elementsToFind.push(`#${button.id}`));
        // Hide btn-group if no buttons are defined
        const clsBtnGroup = (buttons.length === 0) ? ' hidden' : '';
        let $sideview = $(`
      <div class="sideview sideview-map custom-scroll">
        <div class="iw-header">
          <i class="exit fa-solid fa-xmark fa-fw"></i>
        </div>
        <div class="table-content">
          ${table}
        </div>
        <div class="btn-group btn-group-block ${clsBtnGroup}">
          ${buttons.map((button) => (`<button id="${button.id}" class="btn btn-white btn-sm">${button.text}</button>`))}
        </div>
      </div>
    `);
        initTableAccordions($sideview);
        $sideview.find('.exit').on('click', _ => purgatoryService.closeInfoWindow('clicked', $sideview));
        const handle = purgatoryService.createCloseHandler($sideview, hardwire);
        $sideview.toggleClass('sideview-has-buttons', buttons.length > 0);
        const elements = elementsToFind.map(selector => $sideview.find(selector));
        // const height = ($nest ? $nest!.height() : undefined) || tablePriority.find('tr').length * 21
        const fheight = $sideview.find('.table-content tr').length * 21;
        $sideview.attr('summary-height', fheight);
        $sideview.attr('full-height', fheight * 2);
        AInfoWindowHelper.initDraggableWindow({
            $window: $sideview
        });
        _getEle$(nest ?? '#AjaxContent').append($sideview);
        const { left, top } = $parent.offset();
        $sideview.css({
            left,
            top,
            height: height ?? $sideview.attr('summary-height')
        });
        return { $window: $sideview, elements, close: handle.close };
    }
    async showInfoWindowDoubleCustom({ parent, nest, tables, options }) {
        await purgatoryService.closeInfoWindow();
        const $parent = _getEle$(parent);
        const $nest = nest ? _getEle$(nest) : null;
        const { buttons } = Object.assign({ buttons: [] }, options);
        const toTranslate = ['View all', 'View less'];
        buttons.map((button) => toTranslate.push(button.text));
        const elementsToFind = ['#info-toggle-data'];
        buttons.map((button) => elementsToFind.push(`#${button.id}`));
        const t = await Loading.waitForPromises(Translate.get(toTranslate));
        let $sideview = $(`
      <div class="sideview sideview-map custom-scroll">
        <div class="iw-header">
          <i class="exit fa-solid fa-xmark fa-fw"></i>
        </div>
        <div class="table-content">
          ${tables.join('')}
        </div>
        <div class="btn-group btn-group-block">
          <button id="info-toggle-data" class="btn btn-white btn-sm">${t['View all']}</button>
          ${buttons.map((button) => (`<button id="${button.id}" class="btn btn-white btn-sm">${t[button.text]}</button>`))}
        </div>
      </div>
    `);
        initTableAccordions($sideview);
        $sideview.find('.exit').on('click', _ => purgatoryService.closeInfoWindow('clicked', $sideview));
        const handle = purgatoryService.createCloseHandler($sideview);
        $sideview.toggleClass('sideview-has-buttons', buttons.length > 0);
        const elements = elementsToFind.map(selector => $sideview.find(selector));
        const [tablePriority, tableFull] = [$sideview.find(`#${PRIORITY_ID}`), $sideview.find(`#${FULLTABLE_ID}`)];
        const height = ($nest ? $nest.height() : undefined) || tablePriority.find('tr').length * 21;
        $sideview.attr('summary-height', height);
        $sideview.attr('full-height', height * 2);
        AInfoWindowHelper.createToggleButtonListeners({
            button: elements.shift(),
            tables: [tablePriority, tableFull],
            translations: t,
            callback: (full) => {
                $sideview.height($sideview.attr(full ? 'full-height' : 'summary-height'));
            }
        });
        _getEle$(nest ?? '#AjaxContent').append($sideview);
        if (nest === undefined) {
            AInfoWindowHelper.initDraggableWindow({ $window: $sideview });
            $sideview.css({ ...$parent.offset(), height: $sideview.attr('summary-height') });
        }
        else {
            $sideview.css({ left: 0, top: 0, right: 0, bottom: 0, height: '100%', 'max-height': '100%' });
        }
        return { elements, close: handle.close };
    }
    /**
     * @deprecated
     */
    showInfoWindowLegacy({ map, marker, pos, content, closeInfoWindow }) {
        if (closeInfoWindow !== false) {
            purgatoryService.closeInfoWindow();
        }
        const infow = new globalThis.InfoWindow();
        infow.setContent(content || '...');
        infow.setPosition(pos ? pos : getCenterAny(marker));
        infow.setOptions({
            pixelOffset: new google.maps.Size(0, -48)
        });
        infow.open(map ?? PageScript.map);
        google.maps.event.addListener(infow, 'domready', function () {
            initTableAccordions(this.getMap().getDiv() ?? '.aci-map');
        });
        purgatoryService.createCloseHandler(infow);
        return infow;
    }
    closeInfoWindow(trigger, $window) {
        // if ($window && $window.prop('isConnected') && $window.length) {
        //   $window.remove()
        // }
        return Events.tryInvoke(EVENTS.INFOWINDOW_CLOSE, {
            trigger: trigger ?? 'other',
            clicked: $window !== undefined
        });
    }
    createCloseHandler(infowindow, hardwire) {
        let eventId;
        const handleCloseFunc = (opt) => {
            if (opt?.trigger !== 'clicked' && hardwire === true) {
                return;
            }
            Events.off(EVENTS.INFOWINDOW_CLOSE, eventId);
            const objToClose = infowindow;
            const { silent } = Object.assign({ silent: false }, opt);
            if (!silent) {
                purgatoryService.revertCachedMarkerColor(undefined);
            }
            if (infowindow instanceof jQuery) {
                objToClose.remove();
            }
            else {
                objToClose.close();
            }
        };
        eventId = Events.hardwire(EVENTS.INFOWINDOW_CLOSE, (opt) => {
            handleCloseFunc(opt);
        });
        return {
            close: (opt) => {
                handleCloseFunc(opt);
            }
        };
    }
    focusOnMarker(marker) {
        // if (marker && configService.get('general.centerMapOnClick')) {
        if (marker && AConfig.get('general.map.centerOnClick', false)) {
            const map = marker?.map || PageScript.map;
            map.setCenter(getCenterAny(marker));
            if (map.getZoom() < 11) {
                map.setZoom(20);
            }
        }
    }
}
