import { AError } from "../../classes/AError.js";
import { COLUMN_DATETIME, DATA_DATETIME } from "../../classes/AGridTypes.js";
import { AConvertToGridColumns, AConvertToGridData, AShowTable, mergeDeep, TransformObjectsToResponse } from "../../utils/tools.js";
export class APage {
    constructor() {
        this.prevSearch = '';
        this.filters = {};
    }
    async init() {
        this.setLanguageOptions();
        this.initFilters();
        FilterManager.load();
        this.refresh().catch(AError.handle);
    }
    extractContextOptions(translationMap) {
        const contextSet = new Set();
        Object.keys(translationMap).map(key => {
            translationMap[key].TranslationInfo.Context.map(context => contextSet.add(context));
        });
        const contextList = [...contextSet].sort();
        return contextList;
    }
    setContextOptions(translationMap, selectedContext = null) {
        const contextList = this.extractContextOptions(translationMap);
        const $context = $('#Page');
        $context.find('option:not([selected], [value="%"])').each((i, option) => { $(option).remove(); });
        const filteredContextList = contextList.filter(context => context !== '');
        filteredContextList.map((context) => {
            const escapedContext = context
                .replace(/&/g, "&amp;")
                .replace(/</g, "&lt;")
                .replace(/>/g, "&gt;")
                .replace(/"/g, "&quot;")
                .replace(/'/g, "&#039;");
            $context.append(`<option>${escapedContext}</option>`);
        });
        if (selectedContext) {
            $context.val(selectedContext);
        }
    }
    getAllLanguages() {
        return $('#LanguageFrame label.form-radio').toArray().map(label => {
            return {
                lang: $(label).text().trim(),
                code: $(label).find('input[type="radio"]').attr('value')
            };
        });
    }
    setLanguageOptions() {
        const $lang = $('#Lang');
        const languages = this.getAllLanguages();
        languages.map(({ lang, code }) => {
            const attr = (code === Language) ? ' selected="selected"' : '';
            $lang.append(`<option ${attr} value="${code}">${lang}</option>`);
        });
    }
    initFilters() {
        const $lang = $('#Lang');
        $lang.on('change', e => {
            this.refresh();
        });
        const $pages = $('#Page');
        $pages.on('change', e => {
            const $selected = $(e.target);
            const filter = $selected.val();
            if (filter !== '%') {
                this.filters['page'] = (record) => {
                    return record.Context.includes(filter);
                };
            }
            else {
                delete this.filters['page'];
            }
            this.applyFilters();
        });
        // const $search = $('#Search')
        // $search.on('keyup', _ => {
        //   const searchVal = (<string>($search.val() || '')).toLowerCase()
        //   if (searchVal && searchVal.length) {
        //     this.filters['search'] = (record) => {
        //       const { Translation, OriginalValue, OriginalValues } = record
        //       return Translation.toLowerCase().includes(searchVal) ||
        //         OriginalValue.toLowerCase().includes(searchVal) ||
        //         OriginalValues.toString().toLowerCase().includes(searchVal)
        //     }
        //   } else {
        //     delete this.filters['search']
        //   }
        //   this.applyFilters()
        // })
    }
    applyFilters() {
        const filterFunctions = Object.values(this.filters);
        this.grid.store.clearFilters();
        this.grid.store.filterBy(record => {
            let allTrue = true;
            filterFunctions.map(f => {
                if (f(record) === false) {
                    allTrue = false;
                }
            });
            return allTrue;
        });
    }
    onSearchFieldChange(a) {
        this.grid.store.removeFilter('searchFilter');
        this.grid.features['search'].search(a.value, false);
    }
    onSearchFieldClear() {
        this.grid.features['search'].clear();
    }
    applyFilter() {
        const { found, find } = this.grid.features['search'];
        if (this.prevSearch === find || find === undefined) {
            return;
        }
        this.prevSearch = find;
        const ids = found.map(row => row.data.id);
        console.log('ids', ids);
        // const searchColumns = columns.visibleColumns.filter(c => c['searchable'] === true).map(c => c['field'])
        this.grid.store.filter({
            id: 'searchFilter',
            filterBy: (record) => ids.includes(record['id']),
        });
    }
    async refresh() {
        const { Lang, Page } = FilterManager.saveExplicit();
        const translations = mergeDeep({}, await Loading.waitForPromises(Translate.requestLanguage()));
        this.setContextOptions(translations, Page);
        Object.keys(translations).filter(key => {
            if (!Object.keys(translations[key].Languages).includes(Lang)) {
                delete translations[key];
            }
        });
        Object.keys(translations).map(key => {
            translations[key].OriginalValue = key;
            Object.assign(translations[key], translations[key].Languages[Lang]);
            delete translations[key].Languages;
            Object.assign(translations[key], translations[key].TranslationInfo);
            delete translations[key].TranslationInfo;
        });
        const response = await Loading.waitForPromises(TransformObjectsToResponse(translations));
        const NO_EDITOR = { editor: false };
        const HAS_EDITOR = { editor: 'text' };
        let columns = AConvertToGridColumns(response, {
            'Verified': Object.assign({
                width: '10%',
                type: 'widget',
                widgets: [{
                        type: 'checkbox',
                        onAction: ({ source, userAction, value }) => {
                            if (userAction) {
                                // const { record } = source.cellInfo
                                // const { OriginalValue, Translation } = record
                                // Disable unchecking functionality
                                if (value === false) {
                                    source.value = true;
                                }
                            }
                        }
                    }]
            }, NO_EDITOR),
            'OriginalValue': Object.assign({
                width: '30%'
            }, NO_EDITOR),
            'Translation': Object.assign({
                width: '30%',
                editor: 'text'
            }, HAS_EDITOR),
            'OriginalValues': Object.assign({
                hidden: true
            }, NO_EDITOR),
            'OldTranslations': Object.assign({
                hidden: true
            }, NO_EDITOR),
            'Context': Object.assign({
                hidden: true
            }, NO_EDITOR),
            'Created': Object.assign({
                width: '15%'
            }, NO_EDITOR, COLUMN_DATETIME),
            'Accessed': Object.assign({
                width: '15%'
            }, NO_EDITOR, COLUMN_DATETIME),
            'ModificationTime': Object.assign({}, NO_EDITOR, COLUMN_DATETIME),
            'ModificationUser': Object.assign({}, NO_EDITOR)
        });
        const data = AConvertToGridData(response, {
            'Verified': (value) => value,
            'Created': (value) => value !== '0000-00-00T00:00:00.000Z' ? DATA_DATETIME(value) : null,
            'Accessed': (value) => value !== '0000-00-00T00:00:00.000Z' ? DATA_DATETIME(value) : null,
            'ModificationTime': (value) => value !== '0000-00-00T00:00:00.000Z' ? DATA_DATETIME(value) : null,
            'OldTranslations': (value) => JSON.stringify(value),
        });
        const sorting = ["Verified", "OriginalValue", "Translation", "OriginalValues", "OldTranslations", "Context", "Created", "Accessed", "ModificationTime", "ModificationUser"];
        const sortedColumns = [];
        sorting.forEach((key) => {
            var found = false;
            columns = columns.filter((item) => {
                if (!found && item.field == key) {
                    sortedColumns.push(item);
                    found = true;
                    return false;
                }
                else
                    return true;
            });
        });
        this.grid = AShowTable({
            aci: { columnWidth: 300 },
            appendTo: 'table-bryntum',
            columns: sortedColumns,
            data: data,
            tbar: [
                {
                    type: 'text',
                    ref: 'searchField',
                    clearable: true,
                    label: '<i class="b-icon b-icon-search"></i>',
                    listeners: {
                        // input: 'onSearchFieldInput',
                        change: 'onSearchFieldChange',
                        clear: 'onSearchFieldClear',
                        thisObj: this
                    }
                }
            ],
            features: {
                search: true,
                cellEdit: {
                    disabled: false,
                    onEditorBeforeComplete: ({ record, oldValue, value }) => {
                        if (oldValue != value) {
                            Loading.waitForPromises(Translate.updateTranslation(Lang, record.OriginalValue, value, true)).then(_ => {
                                record.Verified = true;
                                console.log('done editing', record);
                            });
                        }
                    }
                }
            }
        });
        // TODO: Implement
        // this.grid.on('search', (e) => this.searchHandler.applyFilter(e))
        // this.grid.on('clearsearch', (e) => this.searchHandler.onSearchFieldClear(e))
        this.grid.on('search', () => this.applyFilter());
        this.applyFilters();
    }
}
export function css() {
    return ( /*html*/`
    <style>
      .b-search-hit {
        white-space: pre;
      }
    </style>
  `);
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column c-scroll col-12">
        <div class="form-group">
          <label class="form-label" for="Lang">Language</label>
          <select id="Lang" type="text" class="form-select">
          </select>
        </div>
        <div class="form-group">
          <label class="form-label" for="Page">Context</label>
          <select id="Page" type="text" class="form-select">
            <option value="%">All</option>
          </select>
        </div>
      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12 hidden" id="RefreshButton">Show</button>
      </div>
    </div>
    <div id="Rapport" class="flex-child bryntum-container has-footer-2">
      <div id="table-bryntum"></div>
      
      <div class="columns footer aci">
        <div class="column col-2">
          <div id="count" class="text">Viewing <span>0</span> Records</div>
        </div>
        <div class="column col-2 col-ml-auto">
          <button id="export" class="btn btn-primary col-12" disabled="disabled">Export</button>
        </div>
      </div>
    </div>
  `);
}
